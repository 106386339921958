<template>
  <div class="van-main my-order-detail">
    <van-cell-group title="订单信息">
      <van-cell title="订单编号" :value="utils.isEffectiveCommon(orderForm.orderNo)" />
      <van-cell title="订单名称" :value="utils.isEffectiveCommon(orderForm.orderName)" />
      <van-cell title="供应商名称" :value="utils.isEffectiveCommon(orderForm.usEnterpriseName)" />
      <!-- <van-cell title="厂号" :value="utils.isEffectiveCommon(orderForm.factoryNumber)" /> -->
      <van-cell title="产品类型" :value="utils.statusFormat(String(orderForm.productType), 'ProjectProductType')" />
      <van-cell title="一级分类" :value="utils.isEffectiveCommon(orderForm.cargoClassifyParentName)" />
      <van-cell title="二级分类" :value="utils.isEffectiveCommon(orderForm.cargoClassifyName)" />
      <van-cell title="原币币种" :value="utils.statusFormat(String(orderTradingSchemeForm.currency), 'OriginalCurrency')" />
      <van-cell title="预估原币总金额" :value="utils.isEffectiveCommon(orderTradingSchemeForm.totalMoney)" />
      <van-cell title="预估柜数" :value="utils.isEffectiveCommon(orderTradingSchemeForm.customerCabinetTotalNumber)" />
    </van-cell-group>
    <div class="product-list">
      <van-cell-group v-for="(item, index) in orderTradingSchemeForm.orderTransactionPurchaseInfoVoList" :key="item.id" class="product-item">
        <van-row class="title">
          {{ index + 1 }}
        </van-row>
        <van-cell title="合同号" :value="item.pi" />
        <van-cell title="合同日期" :value="utils.dateFormat(item.contractDate)" />
        <van-cell title="货物名称" :value="item.productName" />
        <van-cell title="国家" :value="utils.statusFormat(item.country, 'Country')" />
        <van-cell title="原产地" :value="item.countryOfOrigin" />
        <van-cell title="重量" :value="item.weight" />
      </van-cell-group>
    </div>
    <back-button />
  </div>
</template>

<script>
import { Cell, CellGroup, Search, List, Divider, Button, Toast, Row } from 'vant'
import BackButton from '@/components/back-button'
export default {
  components: {
    BackButton,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [List.name]: List,
    [Button.name]: Button,
    [Divider.name]: Divider,
    [Toast.name]: Toast,
    [Row.name]: Row
  },
  data () {
    return {
      id: this.$route.query.id,
      orderForm: {
      },
      orderTradingSchemeForm: {}
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      Toast.loading({ duration: 0, forbidClick: true })
      this.api.order.detailNongtou({ orderId: this.id }).then(result => {
        this.orderForm = result.data.value
        this.getTradingScheme()
      }).finally(() => {
        Toast.clear()
      })
    },
    getTradingScheme () {
      this.api.order.dealPlanNongtou({ orderId: this.orderForm.orderId }).then(result => {
        this.orderTradingSchemeForm = result.data.value
      }).finally(() => {
        Toast.clear()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.my-order-detail {
  .product-list {
    padding: 0px 20px 70px 20px;
    .title {
      text-align: center;
      font-size: 14px;
      font-weight: 700;
      height: 40px;
      line-height: 40px;
      background-color: #F2F2F2;
    }
    .product-item {
      border:1px solid #F2F2F2;
      border-radius: 5px;
    }
  }
}
</style>
