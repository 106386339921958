var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main my-order-detail" },
    [
      _c(
        "van-cell-group",
        { attrs: { title: "订单信息" } },
        [
          _c("van-cell", {
            attrs: {
              title: "订单编号",
              value: _vm.utils.isEffectiveCommon(_vm.orderForm.orderNo)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "订单名称",
              value: _vm.utils.isEffectiveCommon(_vm.orderForm.orderName)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "供应商名称",
              value: _vm.utils.isEffectiveCommon(_vm.orderForm.usEnterpriseName)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "产品类型",
              value: _vm.utils.statusFormat(
                String(_vm.orderForm.productType),
                "ProjectProductType"
              )
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "一级分类",
              value: _vm.utils.isEffectiveCommon(
                _vm.orderForm.cargoClassifyParentName
              )
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "二级分类",
              value: _vm.utils.isEffectiveCommon(
                _vm.orderForm.cargoClassifyName
              )
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "原币币种",
              value: _vm.utils.statusFormat(
                String(_vm.orderTradingSchemeForm.currency),
                "OriginalCurrency"
              )
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "预估原币总金额",
              value: _vm.utils.isEffectiveCommon(
                _vm.orderTradingSchemeForm.totalMoney
              )
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "预估柜数",
              value: _vm.utils.isEffectiveCommon(
                _vm.orderTradingSchemeForm.customerCabinetTotalNumber
              )
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "product-list" },
        _vm._l(
          _vm.orderTradingSchemeForm.orderTransactionPurchaseInfoVoList,
          function(item, index) {
            return _c(
              "van-cell-group",
              { key: item.id, staticClass: "product-item" },
              [
                _c("van-row", { staticClass: "title" }, [
                  _vm._v(" " + _vm._s(index + 1) + " ")
                ]),
                _c("van-cell", { attrs: { title: "合同号", value: item.pi } }),
                _c("van-cell", {
                  attrs: {
                    title: "合同日期",
                    value: _vm.utils.dateFormat(item.contractDate)
                  }
                }),
                _c("van-cell", {
                  attrs: { title: "货物名称", value: item.productName }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "国家",
                    value: _vm.utils.statusFormat(item.country, "Country")
                  }
                }),
                _c("van-cell", {
                  attrs: { title: "原产地", value: item.countryOfOrigin }
                }),
                _c("van-cell", { attrs: { title: "重量", value: item.weight } })
              ],
              1
            )
          }
        ),
        1
      ),
      _c("back-button")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }